<template>
  <ContentWrapper :links="topLinks">
    <Alert type="error">
      商品标题或者内容中出现以下列表中的词汇会无法发布，请谨慎管理
    </Alert>
    <ButtonGroup size="large">
      <Button type="primary" @click="submitHandler" :disabled="loading">保存</Button>
    </ButtonGroup>
    <Form>
      <FormItem label="商品敏感词列表">
        <Input
          placeholder="敏感词列表一行一个"
          type="textarea"
          :rows="14"
          v-model="blacklist"
        />
        <span class="app-settings-key"></span>
      </FormItem>

    </Form>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import { queryItemBlacklist, putItemBlacklist } from "@/api"
import { apiUtil } from "@/util"

export default {
  name: "ItemBlacklistPage",
  components: { ContentWrapper },
  data() {
    return {
      topLinks: [{ title: "商品敏感词", link: this.$route.path }],
      blacklist: "",
      loading: false,
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      apiUtil.call(this, async () => {
        this.loading = true
        const data = await queryItemBlacklist()
        this.blacklist = data
        this.loading = false
      })
    },
    async submitHandler() {
      apiUtil.call(this, async () => {
        this.loading = true
        await putItemBlacklist(this.blacklist)
        this.loading = false
        await this.reload()
      })
    },
  },
}
</script>

<style scope>

p.preview {
  width: 200px;
  height: 100px;
  display: inline-block;
  margin-right: 10px;
}

p.preview > span {
  display: block;
  width: 120px;
  height: 40px;
  margin: 30px auto 0;
  color: #fff;
  text-align: center;
  line-height: 40px;
}

p.white {
  background: #fff;
}

p.black {
  background: #333;
}
</style>
